import {combineReducers} from 'redux'

import app from './app/slice'
import user from './user/slice'



const rootReducer = combineReducers({
    app,
    user
})

export default rootReducer