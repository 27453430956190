import { Spin } from 'antd'



const Loading = () => {
    return <Spin spinning={true} size="large">
        <div style={{ minHeight: '100vh' }}></div>
    </Spin>
}

export default Loading