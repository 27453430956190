
import storage from '../../util/storage'
import { RootState } from '../store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { changeLanguage } from "../../config/i18n"
import { LANG_VALUE } from '../../common/enum'


interface AppType {
  routerName: string,
  lang: string,
  languageId: string,
  languageList: [],
  accessToken: string,
  dialingCodeList: [],
  disclaimer: {},
  departmentList: [],
  regionList: [],
  districtList: [],
  hospitalList: [],
  designatedMedicalInstitutionList: []
}


const initialState: AppType = {
  routerName: '/home',
  lang: localStorage.getItem('lang') || "en",
  languageId: localStorage.getItem('languageId') || "",
  languageList: JSON.parse(localStorage.getItem('languageList') || '[]'),
  accessToken: localStorage.getItem('accessToken') || "",
  dialingCodeList: JSON.parse(localStorage.getItem('dialingCodeList') || '[]'),
  disclaimer: JSON.parse(localStorage.getItem('disclaimer') || '{}'),
  departmentList: JSON.parse(localStorage.getItem('departmentList') || '[]'),
  regionList: JSON.parse(localStorage.getItem('regionList') || '[]'),
  districtList: JSON.parse(localStorage.getItem('districtList') || '[]'),
  hospitalList: JSON.parse(localStorage.getItem('hospitalList') || '[]'),
  designatedMedicalInstitutionList: []
}


export const appStore = createSlice({
  name: 'app', //类似于命名空间，（取个名字）
  initialState, //引用你写的状态=
  //reducers 里面包裹的是同步的方法
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
    setConfigData: (state, action: PayloadAction<any>) => {
      let lang = localStorage.getItem('lang') || navigator.language || 'en'
      let config = action.payload
      console.log('config', config)
      // 语言
      let languageList = config.languageList.map((item: any) => {
        return {
          key: item.languageCode,
          label: item.name,
          id: item.id
        }
      })
      state.languageList = languageList
      storage.setNotString('languageList', languageList)
      config.languageList.forEach(async (item: any) => {
        if ((lang === 'zh-CN' || lang === 'zh') && item.languageCode === 'sc') {
          language_encapsulation<typeof item>(item.id, item, item.languageCode)
          state.lang = item.languageCode
          changeLanguage(LANG_VALUE.CN)
          state.languageId = item.id
        } else if ((lang === 'zh-TW' || lang === 'zh-HK') && item.languageCode === 'tc') {
          language_encapsulation<typeof item>(item.id, item, item.languageCode)
          state.lang = item.languageCode
          state.languageId = item.id
          changeLanguage(LANG_VALUE.HK)
        } else if (lang === 'en' && item.languageCode === 'en') {
          // language_encapsulation<typeof item>(item.id, item, 'en')
          // state.lang = 'en'
          // state.languageId = item.id
          // changeLanguage(LANG_VALUE.En)
          language_encapsulation<typeof item>(item.id, item, item.languageCode)
          state.lang = item.languageCode
          state.languageId = item.id
          changeLanguage(LANG_VALUE.En)
        }
      })
      let languageId = state.languageId
      // 平台
      config.userRegistrationPlatformList.forEach((item: any) => {
        if (item.name === 'WebSite') {
          storage.set('userRegistrationPlatformId', item.id)
          storage.set('platformCode', item.platformCode)
        }
      })
      // T&C
      config.termsAndConditionsList.forEach((item: any) => {
        if (item.tncCode === 'REGTC1') {
          if (item.languageData.length > 0) {
            let TnC: any = ''
            item.languageData.forEach((langItem: any) => {
              if (langItem.languageId === languageId) {
                TnC = langItem
              } else if (langItem.languageId !== languageId && TnC === '') {
                TnC = item
              }
            })
            storage.setNotString("T&C", TnC)
          } else {
            storage.setNotString("T&C", item)
          }
        }
      })
      // 免责声明
      config.termsAndConditionsList.forEach((item: any) => {
        if (item.tncCode === 'WEBTC1') {
          if (item.languageData.length > 0) {
            let disclaimer: any = ''
            item.languageData.forEach((langItem: any) => {
              if (langItem.languageId === languageId) {
                disclaimer = langItem
              } else if (langItem.languageId !== languageId && disclaimer === '') {
                disclaimer = item
              }
            })
            storage.setNotString("disclaimer", disclaimer)
          } else {
            storage.setNotString("disclaimer", item)
          }
        }
      })
      // 区号
      let dialingCodeList = config.dialingCodeList.map((item: any) => {
        return {
          label: item.dialingCode,
          value: item.dialingCode
        }
      })
      dialingCodeList.unshift({ label: '请选择', value: '' })
      state.dialingCodeList = dialingCodeList
      // 科室,医院,地区列表
      let hospitalList: any = listHandle(languageId, config.hospitalList)
      let districtList: any = listHandle(languageId, config.districtList)
      let regionList: any = listHandle(languageId, config.countryRegionList)
      storage.setNotString("hospitalList", hospitalList)
      storage.setNotString("districtList", districtList)
      storage.setNotString("regionList", regionList)
      let departmentList: any = deepListHandle(languageId, config.departmentMasterList).map((item: any) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          disabled: true,
          children: item.childrenList
        }
      })
      // let departmentList={
      //   departmentSubList:arr.childrenList,
      //   iconUrl:arr.iconUrl,
      //   name:arr.name,
      //   id:arr.id,
      //   sequence:arr.sequence
      // }

      // 药械通
      let designatedMedicalInstitutionList: any = config.designatedMedicalInstitutionList.map((item: any) => {
        if (item.languageData.length > 0) {
          let retuenItem: any = ''
          item.languageData.forEach((i: any) => {
            if (i.languageId === languageId) {
              if (item.districtData.languageData.length > 0) {
                item.districtData.languageData.forEach((i: any) => {
                  if (i.languageId === languageId) {
                    retuenItem = {
                      district: i.name,
                      ...item
                    }
                  } else {
                    retuenItem = {
                      district: item.districtData.name,
                      ...item
                    }
                  }
                })
              } else {
                retuenItem = {
                  district: item.districtData.name,
                  ...item
                }
              }
            } else {
              if (item.districtData.languageData.length > 0) {
                item.districtData.languageData.forEach((i: any) => {
                  if (i.languageId === languageId) {
                    retuenItem = {
                      district: i.name,
                      ...item
                    }
                  } else {
                    retuenItem = {
                      district: item.districtData.name,
                      ...item
                    }
                  }
                })
              } else {
                retuenItem = {
                  district: item.districtData.name,
                  ...item
                }
              }
            }
          })
          return retuenItem
        } else {
          if (item.districtData.languageData.length > 0) {
            item.districtData.languageData.forEach((i: any) => {
              if (i.languageId === languageId) {
                return {
                  district: i.name,
                  ...item
                }
              } else {
                return {
                  district: item.districtData.name,
                  ...item
                }
              }
            })
          } else {
            return {
              district: item.districtData.name,
              ...item
            }
          }
        }
      })
      console.log(4343, designatedMedicalInstitutionList)
      state.designatedMedicalInstitutionList = designatedMedicalInstitutionList
      storage.setNotString("departmentList", departmentList)
      state.hospitalList = hospitalList
      state.districtList = districtList
      state.regionList = regionList
      state.departmentList = departmentList
    },
    changeRouterName: (state, action: PayloadAction<string>) => {
      state.routerName = action.payload
    },
    setLang: (state, action: PayloadAction<any>) => {
      state.lang = action.payload
    },
    setLanguageId: (state, action: PayloadAction<any>) => {
      state.languageId = action.payload
    },
  }
})



export const { changeRouterName, setLang, setLanguageId, setConfigData, setToken } = appStore.actions
export const app = (state: RootState) => state.app
export default appStore.reducer


const language_encapsulation = async <T>(id: string, item: T, lang: string) => {
  await storage.set("languageId", id)
  await storage.setNotString("languageItem", item)
  await storage.set("lang", lang)
}

// 普通列表处理
const listHandle = (id: string, list: Array<any>) => {
  let returnList = list.map((item: any) => {
    if (item.languageData.length > 0) {
      let Item: any = ''
      item.languageData.forEach((i: any) => {
        if (i.languageId === id) {
          Item = {
            ...i,
            id: item.id
          }
        } else if (i.languageId !== id && Item == '') {
          Item = item
        }
      })
      return Item
    } else {
      return item
    }
  })
  return returnList
}

// 也是列表处理，深层次
const deepListHandle = (id: string, list: Array<any>) => {
  let returnList = list.map((item: any) => {
    if (item.languageData.length > 0) {
      let Item: any = ''
      item.languageData.forEach((i: any) => {
        if (i.languageId === id) {
          let itemId = ''
          let childrenList
          Object.keys(item).forEach((key: string) => {
            if (key.includes('id') || key.includes('Id')) {
              itemId = item[key]
            }
            if (key.includes('list') || key.includes('List')) {
              childrenList = deepListHandle(id, item[key])
            }
          })
          Item = {
            ...item,
            ...i,
            id: itemId,
            childrenList: childrenList
          }
        } else if (i.languageId !== id && Item == '') {
          Item = item
        }
      })
      return Item
    } else {
      return item
    }
  })
  return returnList
}


