import { createAsyncThunk } from "@reduxjs/toolkit"
import httpHelper from "../../util/apiHelper"
import { setConfigData } from "./slice"

export const GetConfigData = createAsyncThunk<any>(
    'api/GetConfigData',
    async (_, thunkAPI) => {
        return httpHelper.get('/ConfigData', {}).then((res) => {
            thunkAPI.dispatch(setConfigData(res.data))
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)