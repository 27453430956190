import { Suspense, useEffect, useState } from 'react';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useRoutes, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useWebDispatch, useWebSelector } from './store/hook';
import MyHeader from './components/MyHeader';
import MyFooter from './components/MyFooter';
// import AuthRoute from './router/AuthRouter';
import { RootState } from "./store/store"
import { changeRouterName } from "./store/app/slice"
import "./config/i18n"
import Loading from './components/Loading'






import router from './router'
import storage from './util/storage'
import { message } from 'antd'
import { GetConfigData } from './store/app/thunk'
import { UserProfile } from './store/user/thunk';
import { setUserInfo } from './store/user/slice';

function App() {
  
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useWebDispatch()

  const [loading, setLoading] = useState(false)

  // 获取config
  const getConfigData = async () => {
    let { payload } = await dispatch(GetConfigData())
    if (!payload.isSuccess) {
      message.error(payload.errorMessage)
    }
  }
  // 获取用户信息
  const getUserInfo = async () => {
    if (localStorage.getItem("accessToken")) {
      let user = await dispatch(UserProfile())
      if (user.payload.isSuccess) {
        dispatch(setUserInfo(user.payload.data))
        storage.setNotString('userInfo', user.payload.data)
      }
    }
  }

  const init = async () => {
    await getConfigData()
    await getUserInfo()
  }
  // 路由拦截
  const routerBeforeEach = () => {
    window.scrollTo(0,0)
    console.log("当前页面=====>", location.pathname, location)
    dispatch(changeRouterName(location.pathname))
  }

  useEffect(() => {
    if (location.pathname === '/') navigate('/home')
    routerBeforeEach()
  }, [location.pathname])

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <MyHeader />
      <Routes>{
        router.map((item: any, i: number) => {
          return <Route key={i} path={item.path} element={
            <Suspense fallback={<Loading />}>
              {/* <AuthRoute> */}
              <item.component></item.component>
              {/* </AuthRoute> */}
            </Suspense>
          }
          />
        })
      }</Routes>
      <MyFooter />

    </>
  );
}

export default App;
