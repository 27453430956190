

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserType {
    userInfo:any
}


const initialState: UserType = {
    userInfo: JSON.parse(localStorage.getItem('userInfo')|| '{}') ,
}

export const userStore = createSlice({
  name: 'user', //类似于命名空间，（取个名字）
  initialState, //引用你写的状态
  //reducers 里面包裹的是同步的方法
  reducers: {
    setUserInfo(state, action: PayloadAction<UserType>) {
        state.userInfo = action.payload
    }
  }
})

export const { setUserInfo } = userStore.actions
export default userStore.reducer



