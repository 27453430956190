import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANG_VALUE } from '../common/enum';
import hk from '../locales/zh-HK.json';
import en from '../locales/en-US.json';
import cn from '../locales/zh-CN.json';

const resources = {
  en: { translation: en },
  tc: { translation: hk },
  sc: { translation: cn },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(), // function to get the current language
    fallbackLng: "tc",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

  
  export function getLanguage(): string {
    const storedLang = localStorage.getItem('lang');
    const browserLang = navigator.language.toLowerCase();
  
    const language = storedLang ?? browserLang;
    
    switch (language) {
      case LANG_VALUE.HK:
      case LANG_VALUE.CN:
      case LANG_VALUE.En:
        return language;
      default:
        return LANG_VALUE.En;
    }
  }
  
  export function changeLanguage(lang: LANG_VALUE): void {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  }

export default i18n;
