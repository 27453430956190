import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";

function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      // serializableCheck: false,
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false,
    })
  })
}

const store = makeStore()


export type RootState = ReturnType<typeof store.getState>
export type WebDispatch = typeof store.dispatch;
export type WebThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;