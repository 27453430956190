export enum LANG_VALUE {
    En = 'en',
    HK = 'tc',
    CN = 'sc'
  }

// export enum LANG_VALUE {
//   En = 'en',
//   HK = 'zh-TW',
//   CN = 'zh-CN'
// }
