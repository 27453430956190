import axios from "axios";
import moment from "moment";
import sha256 from 'sha256'
import { message } from "antd";
// import {sha256} from 'js-sha256'
// import CryptoJS from 'crypto-js';
import {setToken} from '../store/app/slice'


const apiUrl = {
  API_URL: process.env.REACT_APP_API_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL
}
const AppID = process.env.REACT_APP_AppID
const AppSecret = process.env.REACT_APP_AppSecret
const Version = process.env.REACT_APP_Version
/**
 * @description 呼叫 api 成功處理
 * @param {object} response 呼叫 api 成功回覆的資料
 */
const successHandler = (response: { data?: any; config?: any }) => {

  const { data } = response;
  if (response) {
    if (data !== undefined) {
      // 取得呼叫 api 的相關資訊
      const {
        config: { method, url, baseURL },
      } = response;
      let logUrl = "";
      if (baseURL) {
        const re = new RegExp(baseURL, "g");
        logUrl = url.replace(re, baseURL.slice(-1) === "/" ? "/" : "");
      } else {
        logUrl = url;
      }
      writeResponseLog(method, logUrl, data);
      return data;
    }
  }
};

/**
 * @description 呼叫 api 錯誤處理
 * @param {object} error 呼叫 api 發生錯誤時回覆的錯誤資訊
 */

const errorHandler = (error: { response: any }) => {
  const { response } = error;
  if (response) {
    console.log(
      `%c API發生例外錯誤 status code [${response.status}]`,
      "color: #BB2E29; font-size: 14px; font-weight: bold;"
    );
    if (response.status === 401) {
      // alert( {description:t('expire_token')});
      // TODO router Login
      message.error('登入已過期，請重新登入', 3)
      localStorage.removeItem('accessToken')
      setToken('')
    }
  } else {
    console.log(
      `%c 💩💩💩 API發生例外錯誤 💩💩💩${response && response.status ? `status code [${response.status}]` : ""
      }`,
      "color: #BB2E29; font-size: 14px; font-weight: bold;"
    );
    console.log('res', response);
    alert(error);
  }
  return Promise.reject(error);
};

const writeRequestLog = (
  method: string,
  baseURL: string,
  url: string,
  data: {}
) => {
  console.log(
    `%c >>> [${method}]Request(${baseURL}${url}) >>> `,
    "background-color: #7CBC9D; color: white; font-size: 14px; font-weight: bold;"
  );
  console.log(data);
};

/**
 * @description 輸出呼叫 api 回傳的資料
 * @param {string} method 呼叫 api 的 method
 * @param {string} url api 的網址
 * @param {object} data 呼叫 api 回傳的資料
 */
const writeResponseLog = (method: string, url: string, data = {}) => {
  console.log(
    `%c <<< [${method}]Response(${url}) <<< `,
    "background-color: #B22222; color: white; font-size: 14px; font-weight: bold;"
  );
  console.log(data);
};



const postAuth = async (url: string, data: any) => {
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  let signature = await sign(data, timeStamp, 'post');
  writeRequestLog('postAuth', apiUrl.AUTH_URL || '', url, data)
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
    //baseURL: apiUrl.AUTH_URL,
    url: apiUrl.AUTH_URL + url,
    data
  })
    .then(successHandler)
    .catch(errorHandler)
}
const postAuthByToken = async (url: string, data: any) => {
  let token = await localStorage.getItem('accessToken')
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  let signature = await sign(data, timeStamp, 'post');
  writeRequestLog('postAuth', apiUrl.AUTH_URL || '', url, data)
  return axios({
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
    baseURL: apiUrl.AUTH_URL,
    url,
    data
  })
    .then(successHandler)
    .catch(errorHandler)
}

const postAuthToken = async (url: string, data: any) => {
  let token = await localStorage.getItem('otpLoginToken');
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  let signature = await sign(data, timeStamp, 'post');
  writeRequestLog('postAuth', apiUrl.AUTH_URL || '', url, data)
  return axios({
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
    baseURL: apiUrl.AUTH_URL,
    url,
    data
  })
    .then(successHandler)
    .catch(errorHandler)
}

/**
 * @param {string} url api 的功能url
 * @param {object} data 傳送給 api 的參數
 */
const get = async (url: string, data: {}) => {
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  let signature = await sign(data, timeStamp, 'get');
  writeRequestLog("get", apiUrl.API_URL || '', url, data);
  return axios({
    method: 'get',
    // baseURL:apiUrl.API_URL,
    url: apiUrl.API_URL + url,
    headers: {
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
    params: {
      ...data
    }
  })
    .then(successHandler)
    .catch(errorHandler)
}


const getToken = async (url: string, data: {}) => {
  let token = await localStorage.getItem('accessToken');
  writeRequestLog("getToken", apiUrl.API_URL || '', url, data);
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  let signature = await sign(data, timeStamp, 'get');
  return axios({
    method: 'get',
    // baseURL: apiUrl.API_URL,
    url: apiUrl.API_URL + url,
    params: {
      ...data
    },
    headers: {
      Authorization: `Bearer ${token}`,
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
  }).then(successHandler).catch(errorHandler)
}

function jsonToUrlParam(json: any) {
  return Object.keys(json).map(key => key + '=' + json[key]).join('&');
}

const sign = async (params: {}, timeStamp: string, method: 'post' | 'get') => {
  let queryStr = `AppId${AppID}NonceStr${timeStamp}TimeStamp${timeStamp}Version${Version}`;
  // parameter 排序並移除符號
  let bodyStr = `${jsonToUrlParam(params)}`;
  if (method === 'get') {
    bodyStr = `${jsonToUrlParam(params)}`;
  } else if (method === 'post') {
    bodyStr = `body${JSON.stringify(params)}`;
  }

  let plainText = `${AppSecret}${queryStr}${bodyStr}`.toLowerCase();
  return sha256(plainText)
}

const post = async (url: string, data = {}, baseURL: string, token: string | null) => {
  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  const signature = await sign(data, timeStamp, 'post');
  writeRequestLog("post", baseURL, url, data);
  return axios({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      AppID: AppID,
      NonceStr: timeStamp,
      Timestamp: timeStamp,
      Version: Version,
      Signature: signature,
    },
    baseURL,
    url,
    data,
  })
    .then(successHandler)
    .catch(errorHandler);
};

const postToken = async (url: string, data: any) => {
  let token = await localStorage.getItem('accessToken')

  let timeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  const signature = await sign(data, timeStamp, 'post');

  writeRequestLog('postToken', apiUrl.API_URL || '', url, data)
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      AppID: AppID,
      NonceStr: timeStamp,
      TimeStamp: timeStamp,
      Version: Version,
      Signature: signature
    },
    baseURL: apiUrl.API_URL,
    url,
    data
  })
    .then(successHandler)
    .catch(errorHandler)
}

const patch = async (url: string, data = {}) => {
  writeRequestLog("patch", apiUrl.API_URL || '', url, data);
  return axios({
    method: "patch",
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: apiUrl.API_URL,
    url,
    data,
  })
    .then(successHandler)
    .catch(errorHandler);
};


export default {
  get,
  post,
  getToken,
  postToken,
  postAuth,
  postAuthToken,
  patch,
  postAuthByToken
};



