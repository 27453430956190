import { createAsyncThunk } from "@reduxjs/toolkit"
import httpHelper from "../../util/apiHelper"


export const ValidatePhoneNumber = createAsyncThunk<any, any>(
    'auth/ValidatePhoneNumber',
    async (req, thunkAPI) => {
        return httpHelper.postAuth('/Otp/ValidatePhoneNumber', req).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)

export const VerifySmsOtp = createAsyncThunk<any, any>(
    'auth/Otp',
    async (req, thunkAPI) => {
        return httpHelper.postAuth('/Otp/VerifySmsOtp', req).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)

export const CheckRegisterOrLogin = createAsyncThunk<any, any>(
    'auth/CheckRegisterOrLogin',
    async (req, thunkAPI) => {
        return httpHelper.postAuth('/Account/CheckRegisterOrLogin', req).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)

export const UserLogin = createAsyncThunk<any, any>(
    'auth/Login',
    async (req, thunkAPI) => {
        return httpHelper.postAuth('/Account/UserLogin', req).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)

export const UserRegister = createAsyncThunk<any, any>(
    'auth/Register',
    async (req, thunkAPI) => {
        return httpHelper.postAuth('/Account/UserRegister', req).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)

export const UserProfile = createAsyncThunk(
    'api/UserProfile',
    async (_, thunkAPI) => {
        return httpHelper.getToken('/RetrieveUserProfile/GetRetrieveUserProfile',{}).then(res => {
            return res
        }).catch(error => {
            return thunkAPI.rejectWithValue(error)
        })
    }
)