import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { WebDispatch, RootState } from './store';

export const useWebDispatch = () => useDispatch<WebDispatch>()
export const useWebSelector: TypedUseSelectorHook<RootState> = useSelector

export const useApplyTranslation = (lang: string, enStr: string, tcStr: string) => {
  if (lang == 'en') return enStr
  else if (lang == 'tc') return tcStr
}
