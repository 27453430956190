import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { useWebSelector, useWebDispatch } from '../store/hook'
import { RootState } from "../store/store"
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import { setLanguageId, setLang as setStoreLang, setToken } from '../store/app/slice'
import { changeLanguage } from "../config/i18n"
import { useTranslation } from 'react-i18next'


const MyHeader = () => {

    /* hook */
    const navigate = useNavigate()
    const dispatch = useWebDispatch()
    const { t } = useTranslation()


    /* 变量 */
    // 路由
    const navbar = [
        { lebal: t('tabbar.home'), path: 'home', fullPath: '/home' },
        { lebal: t('tabbar.about'), path: 'about', fullPath: '/about' },
        { lebal: t('tabbar.organization'), path: 'organization', fullPath: '/organization' },
        { lebal: t('tabbar.discount'), path: 'discounts', fullPath: '/discounts' },
        { lebal: t('tabbar.new'), path: 'news', fullPath: '/news' },
        { lebal: t('tabbar.hospital'), path: 'hospital', fullPath: '/hospital' },
        { lebal: t('tabbar.doctor'), path: 'doctor', fullPath: '/doctor' }
    ]
    // 记录当前navbar的index
    const [navIndex, setNavIndex] = useState(0)
    // 获取记录在store中的路由名称
    const { routerName } = useWebSelector((state: RootState) => state.app)
    // 语言
    // config中的语言列表
    const { accessToken, languageList, lang } = useWebSelector((state: RootState) => state.app)
    const [localToken] = useState(localStorage.getItem('accessToken'))
    const userMenu = [{ key: 'loginOut', label: t('logout') }]
    const { userInfo } = useWebSelector((state: RootState) => state.user)
    const [isCollapsed, setIsCollapsed] = useState(false)


    /* 方法 */
    const navigator = (path: string, index: number) => {
        setNavIndex(index)
        navigate(`/${path}`)
    }
    // 语言下拉菜单
    const langSelect = async (value: any) => {
        let id = ''
        dispatch(setStoreLang(value))
        languageList.forEach((item: any) => {
            if (item.key === value) {
                id = item.id
            }
        })
        dispatch(setLanguageId(id))
        localStorage.setItem('languageId', id)
        changeLanguage(value)
        window.location.reload()
    }
    // 用户下拉菜单
    const userMenuSelect = (value: any) => {
        if (value === 'loginOut') {
            localStorage.removeItem('accessToken')
            dispatch(setToken(''))
        }
    }


    /* 监听 */
    useEffect(() => {
        navbar.forEach((item, index) => {
            if (item.fullPath === routerName) {
                setNavIndex(index)
            }
        })
        setIsCollapsed(true)
    }, [routerName])



    

    return (
        <div className="container-fluid sticky-top bg-white shadow-sm">
            <div className="container">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
                    <a href="index.html" className="navbar-brand">
                        <img src="../assets/img/logo.svg" height="50" alt="" />
                    </a>
                    <button className={isCollapsed ? 'navbar-toggler collapsed' : 'navbar-toggler'} onClick={() => setIsCollapsed(!isCollapsed)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={isCollapsed ? "collapse navbar-collapse" :"collapse navbar-collapse show"} id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            {navbar.map((item, index) => {
                                return (
                                    <a className={navIndex === index ? 'nav-item nav-link active' : 'nav-item nav-link'} key={index} onClick={() => navigator(item.path, index)}>{item.lebal}</a>
                                )
                            })
                            }
                            <Dropdown menu={{ items: languageList, onClick: (e) => { langSelect(e.key) } }}>
                                <a className="nav-item nav-link">
                                    <Space>
                                        { lang === 'sc' ? '简体中文' : '繁體中文'}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                        <div style={{ marginLeft: '50px', textAlign: 'right' }}>
                            {accessToken && <Dropdown menu={{ items: userMenu, onClick: (e) => { userMenuSelect(e.key) } }}>
                                <a className="nav-item nav-link">
                                    <Space>
                                        <div>{userInfo.lastName ? userInfo.lastName + '  ' + userInfo.firstName : t('to_login')}</div>
                                    </Space>
                                </a>
                            </Dropdown>}
                            {!accessToken && <div onClick={() => {
                                setIsCollapsed(true)
                                navigate('/login')
                            }}>{t('to_login')}</div>}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MyHeader

