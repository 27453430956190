// import AsyncStorage from '@react-native-async-storage/async-storage';

const get = async (key: any) => {
    return await localStorage.getItem(key)
}

const set = async (key: any, value: any) => {
    return await localStorage.setItem(key, value)
}

const getNotString = async (key: any) => {
    return await JSON.parse(localStorage.getItem(key)as string)
}

const setNotString = async (key: any, value: any) => {
    return await localStorage.setItem(key, JSON.stringify(value))
}

export default {
    get,
    set,
    getNotString,
    setNotString
}